<script>
    import Header from "./Header.svelte";
    import "./styles.css";
    import {onMount} from "svelte";
    import {initKeycloak, isAuthenticated} from "../logic/utils/InitializeKeycloak.ts";

    let isAuthenticatedUser = false;


    onMount(async () => {
        await initKeycloak();
        isAuthenticatedUser = isAuthenticated;
    });


</script>
{#if isAuthenticatedUser === true}
    <Header />
    <main>
      <slot />
    </main>
{/if}

<style>

</style>
