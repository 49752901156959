<header>
  <nav class="flex items-center bg-pink-900		 p-3 flex-wrap">
    <a href="/" class="router-link-active router-link-exact-active" aria-current="page">
      <img class="logo" src="/pacman.png" alt="pacman">
    </a>
    <span class="pl-1 text-xl text-white font-bold tracking-wide">Pacman Test API</span>
    <div
      class="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto"
    >
      <button class="btn-header">
        <a class="text-white font-bold" href="/">Home</a>
      </button>

      <button class="btn-header mr-20">
        <a class="text-white font-bold" href="/faq">FAQs</a>
      </button>
    </div>
  </nav>
</header>

